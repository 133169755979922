import { kebabCase } from 'lodash'

import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs'
import { BlogArticlePageQueryResult } from '~/templates/blog/Article'
import { ArticleIdProps } from '~/types/common-props'
import { BlogPageContext } from '~/types/page-contex'
import { convertInDateWithoutTime } from '~/utils'
import ArticleInfo from '~/views/Blog/components/ArticleInfo'

import ArticleAuthorPrime from '../ArticleAuthorPrime'

import * as containerStyles from './ArticlePrimeSection.module.scss'

type ArticleHeroProps = ArticleIdProps &
  Pick<BlogPageContext, 'views'> & {
    data: BlogArticlePageQueryResult
    title: string | null
    url: string | null
    category: any
    stats: number
    authors: Queries.STRAPI_AUTHOR[]
  }

const ArticlePrimeSection = ({
  data,
  title,
  category,
  url,
  articleId,
  stats,
  views,
  authors,
}: ArticleHeroProps) => {
  const modifiedDate = convertInDateWithoutTime(
    data.strapiArticle!.modified_date as string,
  )

  return (
    <div className={containerStyles.articlePrime}>
      <Breadcrumbs
        secondLevel="Blog"
        secondLevelPath="/blog/"
        thirdLevel={category?.name || ''}
        thirdLevelPath={`/blog/categories/${kebabCase(category.url || '')}/`}
        current={title || ''}
        currentPath={`/blog/${url}/`}
      />

      <h1 className={containerStyles.articlePrime__title}>{title}</h1>

      <ArticleInfo
        key={articleId}
        modifiedDate={modifiedDate}
        stats={stats}
        views={views}
        articleId={articleId}
        isArticle
      />

      <div className={containerStyles.articlePrime__author}>
        <ArticleAuthorPrime authors={authors} />
      </div>
    </div>
  )
}

export default ArticlePrimeSection
