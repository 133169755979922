// extracted by mini-css-extract-plugin
export var recentArticles = "cz_q3";
export var recentArticles__link = "cz_q5";
export var recentArticles__post = "cz_q4";
export var recentArticles__postAuthor = "cz_rd";
export var recentArticles__postAuthorImage = "cz_rc";
export var recentArticles__postAuthorName = "cz_rf";
export var recentArticles__postCategory = "cz_q7";
export var recentArticles__postDate = "cz_rj";
export var recentArticles__postDescriprion = "cz_q9";
export var recentArticles__postImage = "cz_q6";
export var recentArticles__postInfo = "cz_rg";
export var recentArticles__postInfoWrapper = "cz_rb";
export var recentArticles__postStats = "cz_rh";
export var recentArticles__postTitle = "cz_q8";
export var recentArticles__title = "cz_rk";